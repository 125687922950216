import React, { useEffect, useState } from "react";

const host = window.location.origin.includes("localhost")
  ? "https://localhost:8443"
  : "https://api.puipi.com";

const FileUpload = ({sendMessage}) => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(true);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setUploadStatus(false);
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    // TODO: cha
    fetch(`${host}/upload`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        const resp = JSON.parse(data);
        setUploadStatus(true);
        if (resp.success) {
          sendMessage(resp.link);
        }
      })
      .catch((error) => {
        setUploadStatus("Error: " + error.message);
      });
  };

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <input type="file" onChange={handleFileChange} />
        <button disabled={!uploadStatus} type="submit">Upload</button>
      </form>
    </div>
  );
};

export default FileUpload;
