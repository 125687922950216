import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import mainSquare from "../../assets/logo.svg";
import mainIntro from "../../assets/banner.png";
import styles from "./index.module.scss";
import { useMediaQuery } from "react-responsive";
import axios from "axios";

const pageList = ["FaceBook"];

let serverConnection;
const host = window.location.origin.includes("localhost")
  ? "https://localhost:8443"
  : "https://api.puipi.com";
const LandingPage = () => {
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 905px)" });
  const [isShowNavList, setIsShowNavList] = useState(false);
  const [step, setStep] = useState(1);
  const [username, setUsername] = useState("");
  const [currentUserCount, setCurrentUserCount] = useState(0);

  const handleMenuClick = () => {
    // history.push("/market");
    window.open("https://www.facebook.com/people/PuiPi-Chat/100090050661123/");
  };

  useEffect(() => {
    const resp = axios.get(`${host}/users`);
    setCurrentUserCount(resp.result);
  }, []);

  useEffect(() => {
    if (username) {
      const urlPath = `${window.location.pathname}/chat/pool&userGender=man&matchGender=girl`;
      serverConnection = new WebSocket(
        window.location.origin.includes("localhost")
          ? `wss://localhost:8443${urlPath}`
          : `wss://api.puipi.com${urlPath}`,
        [username]
      );
      serverConnection.onopen = () => {
        console.log("Websocket connected [pool]");
      };
      serverConnection.onmessage = ({ data }) => {
        console.log(data);
        let { roomId } = JSON.parse(data);
        serverConnection.close();
        history.push(`/chat/${roomId}`);
      };
    }
  }, [username]);

  const handleConfirm = () => {
    setStep(2);
    window.scrollTo(0, document.body.scrollHeight);
    setUsername(Math.random().toString());
  };

  const handleVideoConfirm = () => {
    history.push(`/video-chat/pool`);
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div style={{ display: "flex" }}>
          <img className={styles.mainSquareImage} src={mainSquare}></img>
          <div className={styles.headerTitle}>PuiPi AI</div>
        </div>
        {!isTabletOrMobile ? (
          <>
            <ul className={styles.pageList}>
              {pageList.map((item) => (
                <li onClick={handleMenuClick} key={item}>
                  {item}
                </li>
              ))}
            </ul>
            {/* <button className={styles.joinWaitListButton}>Sign up</button> */}
          </>
        ) : (
          <div
            onClick={() => setIsShowNavList(!isShowNavList)}
            className={styles.listButton}
          >
            <i className="fas fa-bars"></i>
          </div>
        )}
      </header>
      {isShowNavList && (
        <div className={styles.navList}>
          {pageList.map((item) => (
            <div onClick={handleMenuClick} key={item}>
              {item}
            </div>
          ))}
          <div
            onClick={() => setIsShowNavList(!isShowNavList)}
            className={styles.closeList}
          ></div>
        </div>
      )}
      <div>
        <img className={styles.introPic} src={mainIntro}></img>
        {/* <div className={styles.introText}>
          交友聊天
        </div> */}
        <div className={styles.mainIntroText}>智能化配對，語意分析</div>
        <div className={styles.mainIntroText}>
          Intelligent Matchmaking and Semantic Analysis
        </div>
        <div className={styles.joinWaitListButtonContainer}>
          {step === 1 && (
            <div>
              <button
                onClick={handleConfirm}
                className={styles.joinWaitListButton}
              >
                {" "}
                文字配對
              </button>
              <button
                onClick={handleVideoConfirm}
                className={styles.joinWaitListButton}
              >
                {" "}
                視訊配對
              </button>
            </div>
          )}
          {step === 2 && (
            <>
              <div>等待其他使用者加入...</div>
              <div
                className={styles.leave_btn}
                onClick={() => {
                  serverConnection.close();
                  setStep(1);
                  setUsername("");
                }}
              >
                離開
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
