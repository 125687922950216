const ImageDisplay = ({ imageUrl }) => {
  console.log('imageUrl', imageUrl)
  if (!imageUrl) return <></>;
  const isValidImage =
    imageUrl.endsWith(".png") ||
    imageUrl.endsWith(".jpg") ||
    imageUrl.endsWith(".jpeg") ||
    imageUrl.endsWith(".svg");

  return <div>{isValidImage ? <img width={150} height={150} src={imageUrl} alt="Valid" /> : <></>}</div>;
}

export default ImageDisplay;