import React, { useEffect, useRef, useState } from "react";
import { createUUID } from "../../utils/index";
import { useHistory } from "react-router-dom";
import { timeAgo } from "../../utils/index";
import styles from "./index.module.scss";
import axios from "axios";
import FileUpload from "../../components/FileUpload";
import ImageDisplay from "../../components/ImageDisplay";

// One of the following themes
import "@simonwep/pickr/dist/themes/classic.min.css"; // 'classic' theme
import "@simonwep/pickr/dist/themes/monolith.min.css"; // 'monolith' theme
import "@simonwep/pickr/dist/themes/nano.min.css"; // 'nano' theme

var uuid = createUUID();

const PEER_DISCONNECTED_FUNC = (uuid) => {
  return JSON.stringify({
    event: "PEER_DISCONNECTED",
    uuid,
  });
};

const host = window.location.origin.includes("localhost")
  ? "https://localhost:8443"
  : "https://api.puipi.com";

// window.onbeforeunload = function(){
//   return 'Are you sure you want to leave?';
// };

function App() {
  const inputRef = useRef(null);
  const roomId = window.location.pathname.replace("/chat/", "");
  const history = useHistory();
  const [serverConnection, setServerConnection] = useState("");
  const [messages, setMessages] = useState(
    window.localStorage.getItem(`chat-history-${roomId}`)
      ? JSON.parse(window.localStorage.getItem(`chat-history-${roomId}`))
      : []
  );

  const send = function (message, callback) {
    waitForConnection(function () {
      serverConnection.send(message);
      if (typeof callback !== "undefined") {
        callback();
      }
    }, 1000);
  };

  const waitForConnection = function (callback, interval) {
    if (serverConnection.readyState === 1) {
      callback();
    } else {
      setTimeout(function () {
        waitForConnection(callback, interval);
      }, interval);
    }
  };

  useEffect(() => {
    if (messages.length > 0) {
      window.localStorage.setItem(
        `chat-history-${roomId}`,
        JSON.stringify(messages)
      );
      const msgBoxEle = document.querySelector("#message_box");
      msgBoxEle.scrollTo(0, msgBoxEle.scrollHeight);
    }
  }, [messages]);

  useEffect(() => {
    setServerConnection(
      new WebSocket( //
        window.location.origin.includes("localhost")
          ? `wss://localhost:8443${window.location.pathname}`
          : `wss://api.puipi.com${window.location.pathname}`,
        [uuid]
      )
    );
  }, [uuid]);

  useEffect(() => {
    if (!serverConnection) {
      return;
    }

    serverConnection.onclose = () => {
      console.log("ws closed");
      setServerConnection(
        new WebSocket( //
          window.location.origin.includes("localhost")
            ? `wss://localhost:8443${window.location.pathname}`
            : `wss://api.puipi.com${window.location.pathname}`,
          [uuid]
        )
      );
    };

    serverConnection.onopen = () => {
      console.log("Websocket connected [room]");
      setInterval(() => {
        send(JSON.stringify({ h: 1 }));
      }, 10000);
    };
    serverConnection.onmessage = (e) => {
      const payload = JSON.parse(e.data);
      if (payload.event === "text") {
        if (payload.userId === uuid) {
          // self
          setMessages((prev, curr) => [
            ...prev,
            {
              text: payload.data,
              time: Date.now(),
              isSelf: true,
            },
          ]);
        } else {
          setMessages((prev, curr) => [
            ...prev,
            {
              text: payload.data,
              time: Date.now(),
              isSelf: false,
            },
          ]);
        }
      }
    };
  }, [serverConnection]);

  function handleDisconnect() {
    send(
      JSON.stringify({
        event: "text",
        data: "對方已離開",
      })
    );
    send(PEER_DISCONNECTED_FUNC(uuid));
    axios.post(`${host}/end-conversation`, {
      roomId,
      chatContent: window.localStorage.getItem(`chat-history-${roomId}`),
    });
    window.localStorage.removeItem(`chat-history-${roomId}`);
    history.push("/");
  }

  function handleEnter(e) {
    if (e.keyCode === 13) {
      e.preventDefault(); // Ensure it is only this code that runs
      sendMessage(inputRef.current.value);
    }
  }

  const sendMessage = (inputMessage) => {
    if (inputMessage.length > 1000) {
      alert("訊息太長");
      return;
    }
    send(
      JSON.stringify({
        event: "text",
        userId: uuid,
        data: inputMessage,
      })
    );
    inputRef.current.value = "";
  };

  return (
    <div className="App">
      <div id="message_box" className={styles.message_box}>
        {messages?.map((message, idx) => (
          <div
            key={idx}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: message.isSelf ? "end" : "start",
              alignSelf: message.isSelf ? "end" : "start",
            }}
          >
            <div
              style={{
                fontSize: "16px",
                whiteSpace: "nowrap",
              }}
            >
              {timeAgo(message.time)}
            </div>
            <div
              className={styles.text_message}
              style={{
                background: "white",
                color: "black",
              }}
              key={idx}
            >
              {message.text}
              <ImageDisplay imageUrl={message.text} />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.action_footer}>
        <div
          className={styles.leave_btn}
          onClick={() => {
            handleDisconnect();
          }}
        >
          離開
        </div>
        <input
          onKeyDown={handleEnter}
          className={styles.text_input}
          ref={inputRef}
        ></input>
        <div
          className={styles.send_btn}
          onClick={() => {
            sendMessage(inputRef.current.value);
          }}
        >
          傳送
        </div>
      </div>
      <FileUpload sendMessage={sendMessage} />
    </div>
  );
}

export default App;
