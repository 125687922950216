import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import styles from "./index.module.scss";

let serverConnection;
const Pool = () => {
  const [username, setUsername] = useState("");
  const [userGender, setUserGender] = useState("man");
  const [userMatchGender, setUserMatchGender] = useState("girl");
  const inputEl = useRef(null);
  let history = useHistory();

  useEffect(() => {
    if (username) {
      const urlPath = `${window.location.pathname}&userGender=${userGender}&matchGender=${userMatchGender}`;
      serverConnection = new WebSocket(
        window.location.origin.includes("localhost")
          ? `wss://localhost:8443${urlPath}`
          : `wss://api.puipi.com${urlPath}`,
        [username]
      );
      serverConnection.onopen = () => {
        console.log("Websocket connected [pool]");
      };
      serverConnection.onmessage = ({ data }) => {
        let { roomId } = JSON.parse(data);
        serverConnection.close();
        history.push(`/video-chat/room/${roomId}`);
      };
    }
  }, [username]);

  const handleConfirm = () => {
    //setUsername(inputEl.current.value);
    setUsername(Math.random().toString());
  };

  const handleReturnHomePage = () => {
    history.push(`/`);
  };


  return (
    <div className={styles.container}>
      {username ? (
        <>
          <div>等待其他使用者加入...</div>
          <div
            className={styles.leaveBtn}
            onClick={() => {
              serverConnection.close();
              setUsername("");
            }}
          >
            離開
          </div>
        </>
      ) : (
        <>
          {/* <input ref={inputEl} placeholder="請輸入名稱"></input> */}
          <div>
            <span className={styles.selectLabel}>我的性別:</span>
            <select
              className={styles.selectRound}
              value={userGender}
              onChange={(e) => setUserGender(e.target.value)}
              placeholder="我的性別"
            >
              <option value="man">Male</option>
              <option value="girl">Female</option>
            </select>
          </div>
          <div style={{ marginTop: "20px" }}>
            <span className={styles.selectLabel}>配對性別:</span>
            <select
              className={styles.selectRound}
              value={userMatchGender}
              onChange={(e) => setUserMatchGender(e.target.value)}
              placeholder="配對性別"
            >
              <option value="man">Male</option>
              <option value="girl">Female</option>
            </select>
          </div>
          <div className={styles.enterRoomBtn} onClick={handleConfirm}>
            進入房間
          </div>
          <div className={styles.returnHomePageBtn} onClick={handleReturnHomePage}>
            返回
          </div>
        </>
      )}
    </div>
  );
};

export default Pool;
