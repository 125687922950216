import React, { useEffect, useRef, useState } from "react";
import { initCanvas, createUUID, initColorPicker } from "../../../utils/index";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { doUpdatePeers } from "../../../store/peers";
import styles from "./index.module.scss";
// import RemoteVideo from "../../../components/RemoteVideo";

// One of the following themes
import "@simonwep/pickr/dist/themes/classic.min.css"; // 'classic' theme
import "@simonwep/pickr/dist/themes/monolith.min.css"; // 'monolith' theme
import "@simonwep/pickr/dist/themes/nano.min.css"; // 'nano' theme

// window.onerror = function myErrorHandler(errorMsg, url, lineNumber) {
//   alert("Error occured: " + errorMsg); //or any message
//   return false;
// };

const PEER_DISCONNECTED_FUNC = (uuid) => {
  return JSON.stringify({
    event: "PEER_DISCONNECTED",
    uuid,
  });
};

var uuid = createUUID();
var peerConnectionConfig = {
  iceServers: [
    { urls: "stun:stun.stunprotocol.org:3478" },
    { urls: "stun:stun.l.google.com:19302" },
  ],
};
var serverConnection;

const send = function (message, callback) {
  waitForConnection(function () {
    serverConnection.send(message);
    if (typeof callback !== "undefined") {
      callback();
    }
  }, 1000);
};

const waitForConnection = function (callback, interval) {
  if (serverConnection.readyState === 1) {
    callback();
  } else {
    setTimeout(function () {
      waitForConnection(callback, interval);
    }, interval);
  }
};

var localStream;
var isCaller;
var sendChannel;
var receiveChannel;
var captureStream = null;
var peerConnection;

function App() {
  const history = useHistory();
  useEffect(() => {
    serverConnection = new WebSocket( //
      window.location.origin.includes("localhost")
        ? `wss://localhost:8443${window.location.pathname}`
        : `wss://api.puipi.com${window.location.pathname}`,
      [uuid]
    );

    serverConnection.onopen = () => {
      console.log("Websocket connected [room]");
    };
  }, [uuid]);

  const localVideo = useRef(null);
  const remoteVideo = useRef(null);
  const canvasRef = useRef(null);
  const [hasConnectRemote, setHasConnectRemote] = useState(false);
  const [textareaValue, setTextareaValue] = useState("");
  const [reconnecting, setReconnecting] = useState("");
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [status, setStatus] = useState("連線中...");
  const dispatch = useDispatch();
  const videoPeers = useSelector((state) => state.peers.payload) || [];

  useEffect(() => {
    console.log("init");
    init();
    // initColorPicker();
  }, []);

  const init = async () => {
    initWebRTC();
    initUserMedia();
    // initCanvas(canvasRef, sendToChannel);
  };

  useEffect(() => {
    if (videoPeers.length > 1) {
      if (uuid === videoPeers[0].userId) {
        console.log("handleConnect(true)");
        setTimeout(() => {
          handleConnect(true);
        }, 500);
      }
    }
  }, [videoPeers]);

  useEffect(() => {
    if (!localStream) {
      return;
    }
    localStream.getTracks()[1].enabled = videoEnabled;
  }, [videoEnabled]);
  useEffect(() => {
    if (!localStream) {
      return;
    }
    localStream.getTracks()[0].enabled = audioEnabled;
  }, [audioEnabled]);

  let hasReceiveRemoteTrack = false;
  const initWebRTC = () => {
    console.log("init WebRTC");
    peerConnection = new RTCPeerConnection(peerConnectionConfig);
    peerConnection.ontrack = (event) => {
      console.log("got remote track");
      if (hasReceiveRemoteTrack) {
        return;
      }
      hasReceiveRemoteTrack = true;
      // got remote stream
      console.log(event);
      remoteVideo.current.srcObject = event.streams[0];
      setHasConnectRemote(true);
      setStatus("已連線");
    };
    peerConnection.onicecandidate = gotIceCandidate;
    peerConnection.ondatachannel = (e) => {
      // got data channel
      receiveChannel = e.channel;
      receiveChannel.onmessage = onChannelMessage;
    };
    peerConnection.oniceconnectionstatechange = function () {
      if (peerConnection.iceConnectionState === "disconnected") {
        console.log("WebRTC Peer Disconnected");
      }
    };
  };

  const initUserMedia = () => {
    return new Promise((resolve, reject) => {
      const constraints = {
        video: true,
        audio: true,
      };
      serverConnection.onmessage = gotWSMessageFromServer;
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            // After user grant userMedia access
            localStream = stream;
            localVideo.current.srcObject = stream;
            peerConnection.removeStream(localStream);
            peerConnection.addStream(localStream);
            if (isCaller) {
              peerConnection
                .createOffer()
                .then(sendDescription)
                .catch(errorHandler);
            }
            resolve();
          })
          .catch((err) => {
            console.log(err);
            // alert("Please enable camera and microphone");
            // history.push("/pool");
          });
      } else {
        alert("Your browser does not support getUserMedia API");
        history.push("/pool");
      }
    });
  };
  const handleConnect = (_isCaller) => {
    if (!peerConnection) return;
    isCaller = _isCaller;

    if (_isCaller) {
      sendChannel = peerConnection.createDataChannel("sendDataChannel");

      sendChannel.onopen = (e) => {
        console.log("send channel open");
      };
      sendChannel.onmessage = onChannelMessage;
      peerConnection.createOffer().then(sendDescription).catch(errorHandler);
    }
  };

  function handleVideoDisconnect() {
    setVideoEnabled((prev) => !prev);
  }
  function handleAudioDisconnect() {
    setAudioEnabled((prev) => !prev);
  }
  function handleWebRTCDisconnect() {
    send(PEER_DISCONNECTED_FUNC(uuid));
    localStream.getTracks()[0].stop();
    localStream.getTracks()[1].stop();
    onWebRTCDisconnect();
    history.push("/video-chat/pool");
  }

  async function onWebRTCDisconnect() {
    peerConnection.close();
    setStatus("對方已離開");
    // remoteVideo.current.srcObject = null;
    // dispatch(doUpdatePeers([]));
    // initWebRTC();
    // setHasConnectRemote(false);
    // hasReceiveRemoteTrack = false;
  }

  function gotWSMessageFromServer(message) {
    var signal = JSON.parse(message.data);
    console.log("signal", signal);
    if (signal.event === "Room is Full") {
      //alert("Room is Full");
      return;
    }
    if (signal.event === "NEW_USER_CONNECTED") {
      // TODO: dynamic add WebRTC connection
      dispatch(doUpdatePeers(signal.connectionList));
      return;
    }
    if (signal.event === "PEER_DISCONNECTED") {
      onWebRTCDisconnect();
      return;
    }

    if (!isCaller) handleConnect(false);

    // Ignore messages from ourself
    if (signal.uuid === uuid) return;
    if (!peerConnection) return;
    if (signal.sdp) {
      peerConnection
        .setRemoteDescription(new RTCSessionDescription(signal.sdp))
        .then(function () {
          // Only create answers in response to offers
          if (signal.sdp.type === "offer") {
            peerConnection
              .createAnswer()
              .then(sendDescription)
              .catch(errorHandler);
          }
        })
        .catch(errorHandler);
    } else if (signal.ice) {
      peerConnection
        .addIceCandidate(new RTCIceCandidate(signal.ice))
        .catch(errorHandler);
    }
  }
  function errorHandler(error) {
    console.error(error);
    // if(isCaller && !reconnecting) {
    //   console.log('isCaller');
    //   handleWebRTCDisconnect();
    //   setReconnecting(true);
    //   setTimeout(() => {
    //     handleConnect(true);
    //     setReconnecting(false);
    //   }, 1000)
    // }
  }
  function sendDescription(description) {
    console.log("got description");
    console.log(description);
    peerConnection
      .setLocalDescription(description)
      .then(function () {
        send(JSON.stringify({ sdp: peerConnection.localDescription, uuid }));
      })
      .catch(errorHandler);
  }
  function gotIceCandidate(event) {
    if (event.candidate != null) {
      send(JSON.stringify({ ice: event.candidate, uuid }));
    }
  }
  const onChannelMessage = (e) => {
    const data = JSON.parse(e.data);
    if (data.type === "text") {
      setTextareaValue(data.value);
    }
    // canvas drag，這邊記得要把 drawstart 跟 draw 分開，不然會出先遠端畫很快時只有點
    const canvasCtx = canvasRef.current.getContext("2d");
    if (data.type === "drawstart") {
      const { mx, my, color } = data.value;
      canvasCtx.strokeStyle = color;
      canvasCtx.beginPath();
      canvasCtx.moveTo(mx, my);
    }
    if (data.type === "draw") {
      const { lx, ly } = data.value;
      canvasCtx.lineTo(lx, ly);
      canvasCtx.stroke();
    }
    if (data.type === "drawEnd") {
      canvasCtx.strokeStyle = window.currentDrawColor;
    }
    //console.log(`on channel message: ${data.value}`);
  };
  const handleSendTextMessage = (e) => {
    const value = e.target.value;
    setTextareaValue(value);
    sendToChannel("text", value);
  };

  const sendToChannel = (type, value) => {
    try {
      if (receiveChannel) {
        receiveChannel.send(
          JSON.stringify({
            type,
            value,
          })
        );
      } else {
        sendChannel.send(
          JSON.stringify({
            type,
            value,
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  async function handleShareScreen() {
    try {
      captureStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true,
      });
      captureStream.getVideoTracks()[0].onended = () => {
        // Click on browser UI stop sharing button
        console.info("ScreenShare has ended");
        // Switch to camera stream
        localVideo.current.srcObject = localStream;
        peerConnection.removeStream(captureStream);
        peerConnection.addStream(localStream);
        peerConnection.createOffer().then(sendDescription).catch(errorHandler);
      };
    } catch (err) {
      console.error("Error: " + err);
    }
    // Switch to shareScreen stream
    localVideo.current.srcObject = captureStream;
    peerConnection.removeStream(localStream);
    peerConnection.addStream(captureStream);
    peerConnection.createOffer().then(sendDescription).catch(errorHandler);
  }

  return (
    <div className={styles.container}>
      <div style={{ height: "50px" }}>
        {/* <button onClick={() => handleConnect(true)}>連線</button> */}
        {/* <button onClick={() => handleShareScreen()}>分享螢幕</button> */}
        <button
          style={{ marginLeft: "10px" }}
          onClick={() => handleVideoDisconnect()}
        >
          {videoEnabled ? "關閉視訊" : "開啟視訊"}
        </button>
        <button
          style={{ marginLeft: "10px" }}
          onClick={() => handleAudioDisconnect()}
        >
          {audioEnabled ? "關閉音訊" : "開啟音訊"}
        </button>
        {/* <button onClick={() => handleWebRTCDisconnect()}>斷線</button> */}
      </div>
      <div style={{ marginLeft: "10px" }}>{status}</div>
      <div
        className={styles.leaveBtn}
        onClick={() => {
          handleWebRTCDisconnect();
        }}
      >
        離開
      </div>

      <div className={styles.videoArea}>
        <video muted ref={localVideo} autoPlay playsInline></video>
        <div style={{ width: "50px" }} />
        <video ref={remoteVideo} autoPlay playsInline></video>
      </div>
      {/* <div className="color-picker"></div>
      <canvas
        className={styles.canvas}
        ref={canvasRef}
        height={400}
        width={400}
      />
      <textarea
        value={textareaValue}
        onChange={handleSendTextMessage}
      ></textarea> */}
      {/* {videoPeers
        .filter((peers) => peers.userId !== uuid)
        .map((peer) => (
          <RemoteVideo key={peer.userId} peer={peer} />
        ))} */}
    </div>
  );
}

export default App;
