exports.initCanvas = (canvasRef, sendToChannel) => {

  window.currentDrawColor = "#ECD018";
  window.canvasRefCurrent = canvasRef.current.getContext("2d");
  const canvasCtx = canvasRef.current.getContext("2d");
  canvasCtx.fillStyle = "solid";
  canvasCtx.strokeStyle = window.currentDrawColor;
  canvasCtx.lineWidth = 5;
  canvasCtx.lineCap = "round";

  var lastEvent;
  var mouseDown = false;
  const handleMousedown = (e) => {
    lastEvent = e;
    mouseDown = true;
    canvasCtx.beginPath();
    canvasCtx.moveTo(lastEvent.offsetX, lastEvent.offsetY);
    sendToChannel("drawstart", {
      mx: lastEvent.offsetX,
      my: lastEvent.offsetY,
      color: window.currentDrawColor
    });
  };
  const handleMouseMove = (e) => {
    if (mouseDown) {
      canvasCtx.lineTo(e.offsetX, e.offsetY);
      canvasCtx.stroke();
      lastEvent = e;
      sendToChannel("draw", {
        lx: e.offsetX,
        ly: e.offsetY,
      });
    }
  };
  canvasRef.current.addEventListener("mousedown", (e) => {
    handleMousedown(e);
  });
  canvasRef.current.addEventListener("mousemove", (e) => {
    handleMouseMove(e);
  });
  canvasRef.current.addEventListener("mouseup", () => {
    mouseDown = false;
    sendToChannel("drawEnd");
  });
  canvasRef.current.addEventListener("mouseleave", () => {
    mouseDown = false;
    sendToChannel("drawEnd");
  });

  // mobile
  canvasRef.current.addEventListener("touchstart", (e) => {
    const touch = e.touches[0];
    const mouseEvent = new MouseEvent("mousedown", {
      clientX: touch.clientX,
      clientY: touch.clientY,
    });
    canvasRef.current.dispatchEvent(mouseEvent);
  });
  canvasRef.current.addEventListener("touchmove", (e) => {
    const touch = e.touches[0];
    const mouseEvent = new MouseEvent("mousemove", {
      clientX: touch.clientX,
      clientY: touch.clientY,
    });
    canvasRef.current.dispatchEvent(mouseEvent);
  });
  canvasRef.current.addEventListener("touchend", () => {
    mouseDown = false;
  });
  canvasRef.current.addEventListener("touchcancel", () => {
    mouseDown = false;
  });
};

exports.createUUID = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};

exports.initColorPicker = () => {
  const Pickr = require("@simonwep/pickr");
  // Simple example, see optional options for more configuration.
  const pickr = Pickr.create({
    el: ".color-picker",
    theme: "classic", // or 'monolith', or 'nano'
    default: '#ECD018',
    swatches: [
      "rgba(244, 67, 54, 1)",
      "rgba(233, 30, 99, 0.95)",
      "rgba(156, 39, 176, 0.9)",
      "rgba(103, 58, 183, 0.85)",
      "rgba(63, 81, 181, 0.8)",
      "rgba(33, 150, 243, 0.75)",
      "rgba(3, 169, 244, 0.7)",
      "rgba(0, 188, 212, 0.7)",
      "rgba(0, 150, 136, 0.75)",
      "rgba(76, 175, 80, 0.8)",
      "rgba(139, 195, 74, 0.85)",
      "rgba(205, 220, 57, 0.9)",
      "rgba(255, 235, 59, 0.95)",
      "rgba(255, 193, 7, 1)",
    ],

    components: {
      // Main components
      preview: true,
      opacity: true,
      hue: true,

      // Input / output Options
      interaction: {
        hex: true,
        rgba: true,
        hsla: true,
        hsva: true,
        cmyk: true,
        input: true,
        clear: false,
        save: true,
      },
    },
  });
  pickr
    .on("init", (instance) => {
      console.log('Event: "init"', instance);
    })
    .on("hide", (instance) => {
      console.log('Event: "hide"', instance);
    })
    .on("show", (color, instance) => {
      console.log('Event: "show"', color, instance);
    })
    .on("save", (color, instance) => {
      console.log('Event: "save"', color, instance);
      console.log(color.toHEXA().toString())
      const selectedColor = color.toHEXA().toString();
      window.currentDrawColor = selectedColor;
      window.canvasRefCurrent.strokeStyle = selectedColor;
      pickr.hide();
    })
    .on("clear", (instance) => {
      console.log('Event: "clear"', instance);
    })
    .on("change", (color, source, instance) => {
      console.log('Event: "change"', color, source, instance);
    })
    .on("changestop", (source, instance) => {
      console.log('Event: "changestop"', source, instance);
    })
    .on("cancel", (instance) => {
      console.log('Event: "cancel"', instance);
    })
    .on("swatchselect", (color, instance) => {
      console.log('Event: "swatchselect"', color, instance);
    });
};


exports.timeAgo = (timestamp) => {
  const now = new Date();
  const targetTime = new Date(timestamp);
  const diff = now - targetTime;
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return `${seconds} 秒前`;
  } else if (minutes < 60) {
    return `${minutes} 分鐘前`;
  } else if (hours < 24) {
    return `${hours} 小時前`;
  } else {
    return `${days} 天前`;
  }
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

exports.formatDate = (date) => {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')
  );
}
