import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import ChatRoom from "./pages/chatRoom";
import Room from "./pages/videoChat/room";
import Pool from "./pages/videoChat/pool";
import LandingPage from "./pages/landing-page";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          {/* <Route path="/room">
            <Room />
          </Route> */}
          <Route path="/chat">
            <ChatRoom />
          </Route>
          {/* <Route path="/pool">
            <Pool />
          </Route> */}
          <Route path="/video-chat/room">
            <Room />
          </Route>
          <Route path="/video-chat/pool">
            <Pool />
          </Route>
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
